import { IMY24HeroBanner } from '@/components/sections/my24/home/hero-banner';
import { IMY24LineupCarousel } from '@/components/sections/my24/home/lineup-carousel';
import { IMY24Splash } from '@/components/sections/my24/home/splash-page';
import { getCta } from '@/contents/lcv/product/my24/cta';
import { HOME_LINEUP_DATA } from '@/contents/lcv/product/my24/models';

export const META_TAG_DATA = {
  title: 'ອີຊູຊຸລາວ - Isuzu TIS Laos',
  description: 'ຕິດຕາມຂ່າວສານ ແລະ ໂປຼໂມຊັນໃຫມ່ໆ ຂອງອີຊູຊຸໄດ້ທີ່ນີ້',
  img: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ebe5359f8c9db3/62fb311dea0d864c464abcaf/OG_ISUZU.jpg',
  },
};

export const HERO_BANNER_DATA: IMY24HeroBanner = {
  headline: {
    title: 'ອີຊູຊຸເຊວຊ໌ລາວ Isuzu TIS Lao Official',
  },
  items: [
    {
      title:
        'ໃໝ່! ອີຊູຊຸ ດີ​ແມ​ັກ ວີ-ຄຣອສ 4x4 <span>ເໜືອທຸກລິມິດ UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt19c742cfa30897b2/65826f47a8ee4364a4197562/laos-vcross-mobile.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt65966d8efc652604/65826f474135c455885676b9/laos-vcross-desktop.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
      },
      // video: {
      //   src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea1218288e823af3/651d2b7f2b65937d39c575b6/video-v-cross-mobile.mp4',
      // },
      // videoDesktop: {
      //   src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte4dbac641c915dd1/651d27fb1d2dc3b0c7fb9348/video-v-cross-desktop.mp4',
      // },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4721b6e8faee609d/651528c9b16d6d5596554560/logo-new-isuzu-v-cross-4x4-white.png',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 246,
        height: 72,
      },
      // youtube: {
      //   label: 'ເບິ່ງວິດີໂອແບບເຕັມ',
      //   url: '_kfwyc4lRbg',
      //   icon: 'play-round',
      // },
      buttons: [
        {
          label: 'ເບິ່ງລາຍລະອຽດ D-MAX',
          url: '/isuzu-v-cross',
          icon: 'arrow-forward',
          color: 'white',
          outline: true,
        },
      ],
    },
    {
      title:
        'ໃໝ່! ອີຊູຊຸ ດີ​ແມ​ັກ ໄຮ​ແລນ​ເດີ 4 ປະ​ຕູ <span>ເໜືອທຸກລິມິດ UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt259aa17218f77483/65826ed77d6d2e5a0ab8b636/laos-4door-mobile.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf95c133dc4f19281/65826ed7bb2e10eefa00ecfc/laos-4door-desktop.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
      },

      // video: {
      //   src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5d841ad88f0bbaab/651d2f36964d9bc82ec31a38/video-4-door-mobile.mp4',
      // },
      // videoDesktop: {
      //   src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt03da28596ee50f25/651d2f15d09c523e5a779231/video-4-door-desktop.mp4',
      // },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt90599ea4859b6c8c/64fd7594b169adb40804b401/logo-new-isuzu-hi-lander-white.svg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 217,
        height: 72,
      },
      // youtube: {
      //   label: 'ເບິ່ງວິດີໂອແບບເຕັມ',
      //   url: 'fYvUDS2q2uA',
      //   icon: 'play-round',
      // },
      buttons: [
        {
          label: 'ເບິ່ງລາຍລະອຽດ D-MAX',
          url: '/isuzu-pick-up-4-door',
          icon: 'arrow-forward',
          color: 'white',
          outline: true,
        },
      ],
    },
    {
      title:
        'ໃໝ່! ອີຊູຊຸ ດີແມັກ ສະປ໊າກ <span>ເໜືອທຸກລິມິດ UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt877eb6efc26e883f/657bfe8c5753797155e668dd/laos-spark-mobile.jpg',
        alt: 'NEW! ISUZU D-MAX SPARK',
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd5f44deed283298c/657bfe8c7cafa3cd50de8148/laos-spark-desktop.jpg',
        alt: 'NEW! ISUZU D-MAX SPARK',
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd531cf0bb3b4ed81/64fd71a1bb60f75a2b4803cc/logo-new-isuzu-spark-white.svg',
        alt: 'NEW! ISUZU D-MAX SPARK',
        width: 217,
        height: 72,
      },
      buttons: [
        {
          label: 'ເບິ່ງລາຍລະອຽດ D-MAX',
          url: '/isuzu-spark',
          icon: 'arrow-forward',
          color: 'white',
          outline: true,
        },
      ],
    },
    {
      title:
        'The New MU-X be UNCHARTED <span>ຜູ້ນໍາແຫ່ງຄວາມສໍາເລັດ...ທີ່ເໜືອກວ່າໃຜ</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2958a1a0e195ea6b/657bff2b9337917916dbb613/laos-mux-mobile.jpg',
        alt: 'The New MU-X be UNCHARTED',
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltafc89a9d9e7ef4e2/657bff2b4a95e56d3bdc9964/laos-mux-desktop.jpg',
        alt: 'The New MU-X be UNCHARTED',
      },
      // video: {
      //   src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt428dd7e87612380c/6552e9327326a2ebf51db76e/video-mu-x-mobile-3mb.mp4',
      // },
      // videoDesktop: {
      //   src: 'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2cd3676f1c1ed4a4/6552e9328290512d832c87ed/video-mu-x-desktop.mp4',
      // },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt763122c2e54c3734/64ca6ec33b94214081e9e37d/logo-the-new-mu-x-be-uncharted.png',
        alt: 'The New MU-X be UNCHARTED',
        width: 300,
        height: 70,
      },
      // youtube: {
      //   label: 'ເບິ່ງວິດີໂອແບບເຕັມ',
      //   url: 'qZhJKfilYyc',
      //   icon: 'play-round',
      // },
      buttons: [
        {
          label: 'ເບິ່ງລາຍລະອຽດ MU-X',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'white',
          outline: true,
        },
      ],
    },
  ],
};

export const LINEUP_CAROUSEL_DATA: IMY24LineupCarousel = {
  active: true,
  headline: {
    title: 'ສຳຫຼວດລົດ',
  },
  items: HOME_LINEUP_DATA,
};

export const CTA_DATA = {
  ...getCta('home'),
};

export const SPLASH_DATA: IMY24Splash = {
  headline: {
    preTitle: 'ใหม่! อีซูซุ วี-ครอส 4x4',
    title: 'ເໜືອທຸກລິມິດ UNLOCK YOUR POTENTIAL',
    buttons: [
      {
        label: 'เข้าสู่เว็บไซต์',
        color: 'white',
        outline: true,
      },
      {
        label: 'ดูรายละเอียด',
        url: `/isuzu-v-cross`,
        color: 'black',
      },
    ],
  },
  bg: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbf0a66776d7b3c63/651e85ca3366a4fa7db3422b/splash-v-cross-mobile.jpg',
    alt: 'ใหม่! อีซูซุ วี-ครอส 4x4 ເໜືອທຸກລິມິດ UNLOCK YOUR POTENTIAL',
  },
  bgDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc316d9dd0b71363a/651e85a1f3218a57988fef71/splash-v-cross-desktop.jpg',
    alt: 'ใหม่! อีซูซุ วี-ครอส 4x4 ເໜືອທຸກລິມິດ UNLOCK YOUR POTENTIAL',
  },
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc00670d212fad3a0/651b7d94705ef3b84b489df0/logo-v-cross-4x4.png',
    alt: 'NEW! ISUZU V-CROSS 4x4',
    width: 320,
  },
  imageDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5e6bcceb535124b1/65151f8ca0bdc0481b6f1321/logo-new-isuzu-v-cross-4x4-black.png',
    alt: 'NEW! ISUZU V-CROSS 4x4',
    width: 320,
  },
  logo: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb0fa73fb450b7f79/651b7e2b13e22fa420762546/logo-unlock.svg',
    alt: 'Unlock your potential',
    width: 176,
  },
};
