import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTransition } from 'transition-hook';

import MediaItem from '@/components/sections/my24/shared/media-item';
import { NextImage } from '@/components/shared/element/next-image';
import { Text } from '@/components/shared/element/text';
import { Container } from '@/components/shared/layout/container';
import { Flex } from '@/components/shared/layout/flex';
import { Box } from '@/components/shared/my24/box';
import { Button, ButtonWrap } from '@/components/shared/my24/button';
import { PaginationBar } from '@/components/shared/my24/pagination-bar';
import { youtubeURL } from '@/components/shared/utility/format';
import { useWindowSize } from '@/components/shared/utility/hooks';
import { styled } from '@/stitches.config';
import { IHeadline, IItem, IMy24Tracking } from '@/types/shared';

const VideoPopup = dynamic(
  () => import('@/components/sections/my24/shared/video-popup'),
  {
    ssr: false,
  }
);

const HeroSection = styled('section', {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  bc: '$black',
  '@maxlg': {
    '@media (orientation: portrait)': {
      height: 'calc(100vh - var(--header-height))',
      aspectRatio: '2 / 3',
      '@supports (height: 100svh)': {
        height: 'calc(100svh - var(--header-height))',
      },
    },
    '@media (orientation: landscape)': {
      aspectRatio: '3 / 4',
    },
  },
  '@lg': {
    minHeight: 480,
    height: 'calc(100vh - var(--header-height))',
  },
  '& .swiper-container': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transform: 'translateZ(0)',
  },
  '& .swiper-wrapper': {
    transitionTimingFunction: 'initial !important',
  },
  '& .swiper-slide': {
    transform: 'translateZ(0)',
    overflow: 'hidden',
  },
  '& .swiper-slide-duplicate': {
    opacity: 0,
    '&[data-anim="fade"]': {
      opacity: 1,
    },
  },
});

const HeroContent = styled(Box, {
  textAlign: 'center',
  transform: 'translateZ(0)',
  pe: 'none',
  '@lg': {
    textAlign: 'left',
  },
  '& > *': {
    height: '100%',
  },
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    pe: 'none',
  },
  '&::before': {
    top: 0,
    width: '100%',
    height: 200,
    linearGradient: 'rgba(0, 0, 0, 0.25) 0%, rgb(0, 0, 0, 0) 100%',
    '@lg': {
      width: '100%',
      height: '100%',
      linearGradient: '25deg, rgb(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 50%',
    },
  },
  '&::after': {
    width: '100%',
    bottom: 0,
    height: 300,
    zIndex: -1,
    linearGradient: 'rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0, 0.25) 100%',
    '@lg': {
      content: 'none',
    },
  },
});

const HeroContentInner = styled(Flex, {
  position: 'relative',
  height: '100%',
  transform: 'translateZ(0)',
  '@maxlg': {
    '@media (orientation: portrait)': {
      pt: `${(32 / 640) * 100 + 'vh'}`,
      pb: `calc(${(32 / 640) * 100 + 'vh'} + 3px + 32px)`,
    },
    '@media (orientation: landscape)': {
      pt: `${(32 / 360) * 100 + 'vw'}`,
      pb: `calc(${(32 / 360) * 100 + 'vw'} + 3px + 32px)`,
    },
  },
  '@maxxs': {
    '@media (orientation: portrait)': {
      pt: `${(32 / 640) * 100 + 'vh'}`,
      pb: `calc(${(32 / 640) * 100 + 'vh'} + 3px + 24px)`,
    },
    '@media (orientation: landscape)': {
      pt: `${(32 / 360) * 100 + 'vw'}`,
      pb: `calc(${(32 / 360) * 100 + 'vw'} + 3px + 24px)`,
    },
  },
  '@lg': {
    pt: 56,
    pb: 56 + 3 + 40,
  },
});

const ContentTitle = styled(Text, {
  [`& + ${ButtonWrap}`]: {
    mt: '$6',
  },
});

const ContentPreTitle = styled(Text, {
  textShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 6px',
  mt: 12,
  maxWidth: 480,
  mx: 'auto',
  '@sm': {
    mt: 16,
  },
  '@lg': {
    mx: 0,
    mt: 24,
  },
  '& span': {
    '@maxsm': {
      display: 'block !important',
    },
  },
  [`& + ${ContentTitle}`]: {
    mt: '$2',
  },
});

const HeroContentVideo = styled(Box, {
  pe: 'all',
  fontSize: 0,
  '@lg': {
    position: 'absolute',
    right: 0,
    bottom: 56,
  },
});

const HeroContentLogoBox = styled(Box, {
  width: 'calc(200px + (300 - 200) * ((100vw - 360px) / (768 - 360)))',
  aspectRatio: '300 / 72',
  mx: 'auto',
  '@md': {
    width: 300,
  },
  '@lg': {
    width: 'calc(232px + (300 - 232) * ((100vw - 1024px) / (1440 - 1024)))',
    mx: 0,
  },
  '@2xl': {
    width: 300,
  },
  '& > *': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    '& > *': {
      position: 'absolute !important',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      '& > *': {
        '@lg': {
          objectPosition: '0 0 !important',
        },
      },
    },
  },
});

export interface IMY24HeroBanner {
  headline: IHeadline;
  items?: IItem[];
  isCloseSplash?: boolean;
  dataTrack?: IMy24Tracking;
}

const LCVHomeBanner: React.FunctionComponent<IMY24HeroBanner> = ({
  headline: { title },
  items,
  isCloseSplash,
  dataTrack,
}: IMY24HeroBanner) => {
  const [swiperMedia, setSwiperMedia] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [startAnimatePagination, setStartAnimatePagination] = useState(false);
  const [pauseVideo, setPauseVideo] = useState(false);

  const WINDOW_SIZE = useWindowSize();

  const onResetActiveVideo = () => {
    if (!swiperMedia) return;

    setCurrentIndex(swiperMedia.realIndex);
    const INDEX_SLIDE = swiperMedia.activeIndex;
    swiperMedia.slides &&
      swiperMedia.slides.each((o, i) => {
        if (i === INDEX_SLIDE) {
          const VIDEO = o.querySelector('video');

          if (VIDEO) {
            VIDEO.pause();
            VIDEO.currentTime = 0;
            setStartAnimatePagination(false);
          }
        }
      });
  };

  const onPlayMedia = () => {
    if (!swiperMedia) return;
    if (pauseVideo) return;
    if (!isCloseSplash) return;

    const INDEX_SLIDE = swiperMedia.activeIndex;
    const ACTIVE_SLIDE = swiperMedia.slides[INDEX_SLIDE];
    const ACTIVE_VIDEO = ACTIVE_SLIDE.querySelector('video');

    // reset all video except active
    swiperMedia.slides &&
      swiperMedia.slides.each((o, i) => {
        if (i !== INDEX_SLIDE) {
          const VIDEO = o.querySelector('video');

          if (VIDEO) {
            VIDEO.pause();
          }
        }
      });

    if (ACTIVE_VIDEO) {
      // NOTE: video
      const BULLETS = swiperMedia.pagination.bullets;

      const playVideo = () => {
        if (BULLETS) {
          let DURATION = 8;
          if (ACTIVE_VIDEO) {
            DURATION = ACTIVE_VIDEO.duration;
          }
          BULLETS[swiperMedia.realIndex].style.setProperty(
            '--duration',
            `${DURATION}s`
          );
        }

        setStartAnimatePagination(false);
        const promise = ACTIVE_VIDEO.play();
        if (promise !== undefined) {
          promise
            .catch(() => {
              // NOTE: auto-play was prevented
              // show a UI element to let the user manually start playback
            })
            .then(() => {
              // NOTE: auto-play started
              setStartAnimatePagination(true);
            });
        }
      };
      if (ACTIVE_VIDEO.readyState >= 2) {
        playVideo();
      } else {
        ACTIVE_VIDEO.addEventListener(
          'loadedmetadata',
          () => {
            // NOTE: prevent play on same video
            if (
              BULLETS[swiperMedia.realIndex].style.getPropertyValue(
                '--duration'
              ).length != 0
            ) {
              return;
            }

            playVideo();
          },
          { once: true }
        );
      }
    } else {
      // NOTE: image
      setStartAnimatePagination(true);
    }
  };

  const onPauseVideo = () => {
    if (!swiperMedia) return;

    setPauseVideo(true);
    swiperMedia.slides &&
      swiperMedia.slides.each((o) => {
        const VIDEO = o.querySelector('video');
        if (VIDEO) {
          VIDEO.pause();
        }
      });
  };

  const onPlayVideo = () => {
    if (!swiperMedia) return;

    setPauseVideo(false);
    const INDEX_SLIDE = swiperMedia.activeIndex;
    const ACTIVE_SLIDE = swiperMedia.slides[INDEX_SLIDE];
    const ACTIVE_VIDEO = ACTIVE_SLIDE.querySelector('video');

    if (ACTIVE_VIDEO) {
      const promise = ACTIVE_VIDEO.play();
      if (promise !== undefined) {
        promise
          .catch(() => {
            // NOTE: auto-play was prevented
            // show a UI element to let the user manually start playback
          })
          .then(() => {
            // NOTE: auto-play started
          });
      }
    }
  };

  // NOTE: init
  useEffect(() => {
    if (!swiperMedia) return;
    swiperMedia.slides.forEach((o) => {
      const DUP_SLIDE = o.classList.contains('swiper-slide-duplicate');

      if (DUP_SLIDE) {
        o.setAttribute('data-anim', 'fade');
      }
    });
  }, [swiperMedia]);

  const handleAnimationEnd = (e) => {
    if (pauseVideo) return;
    if (e.elapsedTime === 0) return;

    const currentItem = items[swiperMedia.realIndex];
    if (currentItem.video) return;

    onSlideNext();
  };

  // NOTE: handle video event
  const onVideoEnded = () => {
    if (pauseVideo) return;
    onSlideNext();
  };

  useEffect(() => {
    if (!swiperMedia) return;
    if (!isCloseSplash) return;

    // NOTE: progress bar animation end for image
    const BULLETS = swiperMedia.pagination.bullets;

    BULLETS.forEach((o) => {
      o.addEventListener('animationend', handleAnimationEnd);
    });

    swiperMedia.slides &&
      swiperMedia.slides.each((o) => {
        const VIDEO = o.querySelector('video');
        if (VIDEO) {
          VIDEO.addEventListener('ended', onVideoEnded);
        }
      });

    // NOTE: initial index
    setCurrentIndex(swiperMedia.realIndex);
    onPlayMedia();

    return () => {
      BULLETS.forEach((o) => {
        o.removeEventListener('animationend', handleAnimationEnd);
      });

      swiperMedia.slides &&
        swiperMedia.slides.each((o) => {
          const VIDEO = o.querySelector('video');
          if (VIDEO) {
            VIDEO.removeEventListener('ended', onVideoEnded);
          }
        });
    };
  }, [swiperMedia, isCloseSplash]);

  // NOTE: reset slide when video src change (mobile <-> desktop)
  const [isMobile, setIsMobile] = useState(WINDOW_SIZE.width < 1024);
  useEffect(() => {
    setIsMobile(WINDOW_SIZE.width < 1024);
  }, [WINDOW_SIZE]);

  useEffect(() => {
    if (!swiperMedia) return;

    const REAL_INDEX = swiperMedia.realIndex;
    const INDEX_SLIDE = swiperMedia.activeIndex;
    const ACTIVE_SLIDE = swiperMedia.slides[INDEX_SLIDE];
    const ACTIVE_VIDEO = ACTIVE_SLIDE.querySelector('video');

    if (!ACTIVE_VIDEO) return;

    // NOTE: reset slide
    swiperMedia.loopedSlides
      ? swiperMedia.slideToLoop(REAL_INDEX + 1, 0)
      : swiperMedia.slideTo(REAL_INDEX + 1, 0);

    swiperMedia.loopedSlides
      ? swiperMedia.slideToLoop(REAL_INDEX, 0)
      : swiperMedia.slideTo(REAL_INDEX, 0);
  }, [isMobile, swiperMedia]);

  const onSlideNext = () => {
    if (!swiperMedia) return;
    if (pauseVideo) return;

    swiperMedia.slideNext();
  };

  // VIDEO MODAL //
  const [youtubeID, setYoutubeID] = useState('');
  const [modal, setModal] = useState(false);
  const TOGGLE_MODAL_TRANS = useTransition(modal, 330);

  const onOpenModal = (id: string) => {
    if (swiperMedia.animating) return;
    setYoutubeID(id);
    setModal(true);
    onPauseVideo();
    const ALL_SWIPER = document.querySelectorAll('.swiper-container');
    ALL_SWIPER.forEach((o: any) => {
      const SWIPER = o.swiper;
      SWIPER.keyboard.disable();
    });
  };

  const onCloseModal = () => {
    setModal(false);
    onPlayVideo();
    const ALL_SWIPER = document.querySelectorAll('.swiper-container');
    ALL_SWIPER.forEach((o: any) => {
      const SWIPER = o.swiper;
      SWIPER.keyboard.enable();
    });
  };

  return (
    <>
      {items?.length > 0 && (
        <HeroSection data-test="section_banner" className="h-screen-w-header">
          {title && (
            <Text as="h1" size="h1" sr-only>
              {title}
            </Text>
          )}
          <Swiper
            loop
            keyboard
            speed={1000}
            slidesPerView={1}
            threshold={10}
            pagination={{
              el: '.swiper-pagination-home-banner',
              clickable: true,
              renderBullet: function (index, className) {
                return `<div class="${className}" data-test="pagination_${
                  index + 1
                }"><span>${index + 1}</span>
                </div>`;
              },
            }}
            onSwiper={setSwiperMedia}
            onSlideChangeTransitionStart={onResetActiveVideo}
            onSlideChangeTransitionEnd={onPlayMedia}
            data-test={`banner_items`}
          >
            {items?.slice(0, 9).map((item, i) => (
              <SwiperSlide key={i} data-test={`banner_item_${i + 1}`}>
                <MediaItem
                  type={
                    item?.video?.src || item?.videoDesktop?.src
                      ? 'video'
                      : 'image'
                  }
                  image={item?.image}
                  imageDesktop={item?.imageDesktop}
                  video={item?.video?.src}
                  videoDesktop={item?.videoDesktop?.src}
                  cropByRatio={'3:4'}
                  cropByRatioDesktop={'16:9'}
                  priority={i === 0 && isCloseSplash}
                  lazyBoundary={
                    i !== 0 && isCloseSplash && startAnimatePagination
                      ? '0px 0px 0px -1px'
                      : '0px -1px 0px -1px'
                  }
                />
                <HeroContent
                  position="absolute"
                  top="0"
                  left="0"
                  width="w-full"
                  height="h-full"
                  zIndex="1"
                >
                  <Container>
                    <HeroContentInner
                      direction="column"
                      justify={{ '@initial': 'between', '@lg': 'end' }}
                      align={{ '@lg': 'start' }}
                    >
                      <Box css={{ transform: 'translateZ(0)' }}>
                        {item?.logo?.src && (
                          <HeroContentLogoBox position="relative">
                            <NextImage
                              src={item?.logo?.src}
                              alt={item?.logo?.alt}
                              layout={'fill'}
                              objectFit={'contain'}
                              priority={
                                isCloseSplash &&
                                (i === 0 ||
                                  (i === currentIndex + 1 &&
                                    startAnimatePagination))
                                  ? true
                                  : false
                              }
                              lazyBoundary={i !== 0 && '0px 0px 0px -1px'}
                              dataTest="banner_logo"
                              sizes={`(max-width: 374px) and (resolution > 2dppx) ${
                                384 / 3 + `px`
                              }, (max-width: 374px) and (resolution <= 2dppx) ${
                                384 / 2 + `px`
                              }, (max-width: 1023px) and (resolution > 2dppx) ${
                                480 / 3 + `px`
                              }, (max-width: 1023px) and (resolution <= 2dppx) ${
                                480 / 2 + `px`
                              }, (min-width: 1536px) and (resolution >= 2dppx) ${
                                640 / 2 + `px`
                              }, (min-width: 1024px) and (resolution >= 2dppx) ${
                                480 / 2 + `px`
                              }, 320px`}
                            />
                          </HeroContentLogoBox>
                        )}
                        {item?.title && (
                          <ContentPreTitle
                            as="h2"
                            size="h5"
                            font="bold"
                            variant={
                              item?.isTheme === 'light'
                                ? 'text-gray-darkest'
                                : 'text-white'
                            }
                            wrap
                            dangerouslySetInnerHTML={{
                              __html: item?.title,
                            }}
                            data-test="banner_title"
                          />
                        )}
                      </Box>
                      <Box>
                        {item?.youtube && (
                          <HeroContentVideo
                            position={{
                              '@initail': 'relative',
                              '@lg': 'absolute',
                            }}
                            width={{ '@initail': 'w-full', '@lg': 'w-auto' }}
                          >
                            <Button
                              label={item?.youtube?.label}
                              outline={item?.youtube?.outline}
                              icon={item?.youtube?.icon}
                              variant="transparent"
                              size={item?.youtube?.size}
                              onClick={() => onOpenModal(item?.youtube?.url)}
                              tracking={{
                                dataTrack: dataTrack,
                                dataTrackSection: 'banner',
                                dataTrackText: item?.youtube?.label,
                                dataTrackValue: item?.title,
                                dataTrackUrl: youtubeURL(item?.youtube?.url),
                              }}
                              test={{
                                dataTest: 'banner_videoButton',
                              }}
                            />
                          </HeroContentVideo>
                        )}
                        {item?.buttons?.length > 0 && (
                          <ButtonWrap
                            justify={{ '@initial': 'center', '@lg': 'start' }}
                            css={{ mt: 24, width: '100%', pe: 'all' }}
                          >
                            {item?.buttons?.map((button, i) => (
                              <Button
                                key={i}
                                href={button?.url}
                                target={button?.target}
                                outline={button?.outline}
                                label={button?.label}
                                icon={button?.icon}
                                variant={button?.color}
                                size={button?.size}
                                minWidth={button?.minWidth}
                                tracking={{
                                  dataTrack: dataTrack,
                                  dataTrackSection: 'banner',
                                  dataTrackText: button?.label,
                                  dataTrackValue: item?.title,
                                  dataTrackUrl: button?.url,
                                }}
                                test={{
                                  dataTest: `banner_ctaButton_${i + 1}`,
                                }}
                                full
                              />
                            ))}
                          </ButtonWrap>
                        )}
                      </Box>
                    </HeroContentInner>
                  </Container>
                </HeroContent>
              </SwiperSlide>
            ))}
          </Swiper>
          {items?.length > 1 && (
            <PaginationBar
              animateBanner
              startAnimateBanner={startAnimatePagination}
              pauseActiveBar={pauseVideo}
              justify={{ '@lg': 'start' }}
              css={{
                position: 'absolute',
                left: 0,
                '@maxlg': {
                  '@media (orientation: portrait)': {
                    bottom: `${(32 / 640) * 100 + 'vh'}`,
                  },
                  '@media (orientation: landscape)': {
                    bottom: `${(32 / 360) * 100 + 'vw'}`,
                  },
                },
                '@lg': {
                  bottom: 56,
                },
              }}
            >
              <Container>
                <div
                  className="swiper-pagination swiper-pagination-home-banner"
                  data-test="paginations"
                />
              </Container>
            </PaginationBar>
          )}
        </HeroSection>
      )}
      {TOGGLE_MODAL_TRANS.shouldMount && (
        <VideoPopup
          css={{
            opacity: TOGGLE_MODAL_TRANS.stage === 'enter' ? '1' : '0',
            transform:
              TOGGLE_MODAL_TRANS.stage === 'enter' ? 'scale(1)' : 'scale(0.9)',
            transition:
              'opacity 0.33s cubic-bezier(0.33, 1, 0.68, 1), transform 0.33s cubic-bezier(0.33, 1, 0.68, 1)',
          }}
          youtubeId={youtubeID}
          onClose={onCloseModal}
        />
      )}
    </>
  );
};

LCVHomeBanner.defaultProps = {
  dataTrack: 'lcv-home',
};
LCVHomeBanner.displayName = 'SectionMY24HomeHeroBanner';
export default LCVHomeBanner;
